<template>
  <div class="videos">
    <header class="inner-head">
      <div class="flex">
        <h2 class="inner-head-title">Videos</h2>

        <ul class="subnav">
               <li class="inner-head-list-item hover:opacity-50 hover:text-action-blue">
            <button
              v-bind:class="checkTab(0)"
              v-on:click="showVideos(0, 'skill-session')"
              class="focus:outline-none"
            >
              Skills
            </button>
          </li>
               <li class="inner-head-list-item hover:opacity-50 hover:text-action-blue">
            <button
              v-bind:class="checkTab(1)"
              v-on:click="showVideos(1, 'warm-up')"
              class="focus:outline-none"
            >
              Warm Up
            </button>
          </li>
          <li class="inner-head-list-item hover:opacity-50 hover:text-action-blue" 
            v-if="showLancs"
          >
            <button
              v-bind:class="checkTab(2)"
              v-on:click="showVideos(2, 'lancashire')"
              class="focus:outline-none"
            >
              Lancashire
            </button>
          </li>
        </ul>
      </div>
    </header>

    <div class="filter-bar">
      <ul class="actions">
        <li class="opacity-75">Search:</li>
        <li>
          <input
            type="search"
            class="
              filter-bar-search
              focus:outline-none
            "
            name="search"
            v-model="search"
            placeholder="Enter search term..."
          />
        </li>
      </ul>
    </div>

    <p class="m-8 mb-4 text-gray-500">{{ filteredList.length }} videos in view</p>

    <div v-if="errored" class="empty">
      <p>
        We are not able to retrieve this information at the moment, please try
        back later
      </p>
    </div>

    <div id="content-area" v-else>
      <div
        style="width: 100%; text-align: center"
        class="spin-holder mt-4"
        v-if="loading"
      >
        <i class="spinner fa fa-spinner fa-spin"></i>
        <p>Loading Videos</p>
      </div>

      <div v-else>
        <table
          class="
            w-95p
            mx-auto
            mb-2
            h-12
            pt-2
            align-text-bottom
            bg-white
            rounded
            text-left text-gray-400
          "
        >
          <thead>
            <tr>
              <th width="40%" class="pl-3">Title</th>
              <th width="50%">Tags</th>
              <th>Action</th>
            </tr>
          </thead>
        </table>

        <table class="table-wrapper w-95p bg-white mx-auto rounded shadow-sm">
          <tbody>
            <tr v-for="video in filteredList">
              <td width="40%">{{ video.title.String }}</td>
              <td width="50%">{{ video.tags.String }}</td>
              <td class="2u">
                <a
                  @click="display(video)"
                  class="button special small cursor-pointer"
                  v-if="currentUser.isSubscriber"
                  >View</a
                >
                <i v-else class="fas fa-lock text-yellow-500 text-lg"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <modalvideo
      :url="loadVideoPath()"
      @close="showModalVideo = false"
      v-if="selectedVideo != undefined && showModalVideo"
      v-show="showModalVideo"
    ></modalvideo>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BaseService } from "@/services/school.service";

const modalvideo = () => import("@/components/ModalVideo.vue");

export default {
  name: "videos",
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
    }),
    componentLoaded() {
      return this.videos.length > 0;
    },
    showLancs() {
      return this.currentUser.schoolStyle == "lancashire";
    },
    orderedVideos() {
      var vm = this;
      var type = vm.videoType;

      var filtered = vm.videos.filter(function (v) {
        let filtered = true;

        filtered = v.display_key === type;

        return filtered;
      });

      return this.$orderBy(filtered, ["title.String"]);
    },
    filteredList() {
      return this.orderedVideos.filter((item) => {
        var vm = this;
        return (
          item.tags.String.toLowerCase().includes(vm.search.toLowerCase()) ||
          item.title.String.toLowerCase().includes(vm.search.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      loading: true,
      errored: false,
      videos: [],
      selectedVideo: {},
      showModalVideo: false,
      search: "",
      videoType: "skill-session",
            currentTab: 0,

    };
  },
  created() {
    this.loading = true;
    this.fetchData();
  },
  mounted() {
    //this.fetchYears();
  },
  methods: {
    fetchData() {
      BaseService.getAll("videos?types=warm-up,skill-session,lancashire")
        .then((response) => this.buildVideoList(response.data))
        .catch((err) => {
          console.log("Videos " + err);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    buildVideoList(data) {
      this.videos = data;
    },
    display(video) {
      this.selectedVideo = video;
      this.showModalVideo = true;
    },
    loadVideoPath() {
      return this.selectedVideo.service_url_b.String;
    },
    setStyles(value) {
      return this.vid === value
        ? "bg-orange-300 text-white"
        : "bg-white text-gray-400";
    },
    checkTab(value) {
      return this.currentTab == value ? "active" : "";
    },
    showVideos(tab, cat) {
      this.typeSelected = "videos";
      this.currentTab = tab;
      this.videoType = cat;
    },
  },
  components: {
    modalvideo,
  },
};
</script>

<style>
i.icon {
  font-size: 18px;
  padding-left: 4px;
  padding-top: 4px;
}
</style>
